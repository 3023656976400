import React from 'react';
import { connect } from 'react-redux';

import { Form, HiddenField, Input } from './form_for';
import LocalStorageManager from 'lib/localStorageManager';
import PasswordFormLogo from '../svg/password_form_logo';

class SchoolPasswordNew extends React.Component {

  setResetKey = () => {
    LocalStorageManager.save('reset_password', true);
  }

  render() {
    const { schoolId, altColor, i18n, textColor } = this.props;

    return (
      <div className="content-inner">
        <div className="iconed-block">
          <div className="iconed-block-inner">
            <div className="col-set col-set-bordered school-new-pass-form">
              <div className="col">
                <div className="inline-block">
                  <PasswordFormLogo fillColor={altColor} />
                </div>

                <h3 className='new-user-form-header' dangerouslySetInnerHTML={{ __html: i18n.resetPassword.title }}></h3>
                <p dangerouslySetInnerHTML={{ __html: i18n.resetPassword.description }}></p>

                <Form for="user" action="user_password_path" method="POST" className='nm-course-form clear padded-form' onSubmit={this.setResetKey}>
                  <HiddenField name="school_id" value={schoolId} />

                  <div className="nm-form-row distance-sm">
                    <div className='nm-left'>
                      <Input
                        className="nm-input input-email"
                        customValidity={i18n.emailError}
                        id="email-field"
                        name="email"
                        aria-label={i18n.emailAriaLabel}
                        placeholder={i18n.email}
                        required={true}
                        type="email"
                      />
                    </div>
                  </div>

                  <input
                    type="submit"
                    aria-label={i18n.resetPassword.submitButtonAriaLabel}
                    className='nm-btn reset-password-button'
                    style={{color: textColor, backgroundColor: altColor}}
                    value={i18n.resetPassword.submitButton}
                  />
                </Form>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    textColor: state.$$school.getIn(['template_values', 'textColor', 'value']),
    altColor: state.$$school.getIn(['template_values', 'secondaryColor', 'value']),
  };
}

export default connect(mapStateToProps)(SchoolPasswordNew);

import React from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import ConditionalComponent from '../../../components/common/ConditionalComponent';
import MagnifyingGlass from '../../../components/svg/magnifying-glass';

import TinyMceEditorContent from './TinyMceEditorContent'


export default function SchoolDescription({
  courses,
  description,
  i18n,
  isSearchDisplay,
  onSearchResults,
  selectCourseText,
}) {

  return (
    <div className='school-bundles-header'>
      <TinyMceEditorContent className='school-bundles-header-text' text={description} />
      <TinyMceEditorContent className='school-bundles-header-sub-text' text={selectCourseText} />

      <ConditionalComponent isRender={isSearchDisplay}>
        <div className="school-bundles-header-search">
          <ReactSearchAutocomplete
            fuseOptions={{
              keys: ['name', 'price', 'priceAfterDiscount'],
              threshold: 0.3
            }}
            items={courses}
            onSearch={onSearchResults}
            placeholder={i18n.getIn(['students', 'school', 'searchPlaceholder'])}
            showClear={false}
            showIcon={false}
            showNoResults={false}
          />
          <MagnifyingGlass />
        </div>
      </ConditionalComponent>
    </div>
  )
}

import Immutable from 'immutable';
import Url from 'domurl';

import { symbols } from '../actions/layout';
import { symbols as cartSymbols } from '../actions/cart';
import { symbols as bundleSymbols } from '../actions/bundle_join';
import { symbols as teacherSymbols } from '../actions/teacher/edit_lesson';

export const initialState = Immutable.Map();

export default function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case symbols.TOGGLE_BURGER_MENU:
      return state.update('showBurgerMenu', (val) => !val);

    case cartSymbols.AJAX_END:
      if (action.payload && action.payload.cart && (typeof action.payload.cart.size !== 'undefined')) {
        return state.set('cartItemsCount', action.payload.cart.size);
      } else {
        return state;
      }

    case bundleSymbols.ERROR:
      return state.set('flash', Immutable.Map({ text: action.payload.text }));

    case symbols.REPORT_ERROR:
      return state.set('jserror', action.payload);

    case symbols.REPORT_WARNING:
      return state.set('jswarning', action.payload);

    case symbols.CLEAR_ERROR:
      return state.
        set('jserror', undefined).
        set('jswarning', undefined).
        delete('flash');

    case teacherSymbols.NEW_SCHOOL_COLOR:
      return state.set('headerBgColor', action.payload);

    case teacherSymbols.BEGIN_EDIT_BUNDLE_TOC:
      return state.set('isDragging', true);

    case teacherSymbols.END_EDIT_BUNDLE_TOC:
      return state.set('isDragging', false);

    case teacherSymbols.CANCEL_EDIT_BUNDLE_TOC:
      return state.set('isDragging', false);

    case teacherSymbols.SHOW_EDIT_INSTRUCTOR:
      return state.set('isEditingInstructor', true);

    case teacherSymbols.HIDE_EDIT_INSTRUCTOR:
      return state.set('isEditingInstructor', false);

    case teacherSymbols.SET_INSTRUCTOR_DETAILS:
      return setInstructorDetails(state, action);

    default:
      return state;
  }
}

function setInstructorDetails(state, { payload: { nameForDisplay, nameForEmail, email } }) {
  let _state = state;
  if (nameForDisplay) {
    _state = _state.setIn(['instructor', 'name_for_display'], nameForDisplay);
  }

  if (nameForEmail) {
    _state = _state.setIn(['instructor', 'name_for_email'], nameForEmail);
  }

  if (email) {
    _state = _state.setIn(['instructor', 'email'], email);
  }

  return _state;
}


import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import classNames from 'classnames';

import cartActions from '../actions/cart';
import { clearError, reportWarning } from '../actions/layout';

import Container from './container';
import SidebarToc from '../components/lesson/sidebar_toc';
import { LessonProps } from '../constants/bundletoc.js';
import { VideoProps } from '../constants/lesson.js';
import VideoNotes from '../components/lesson/video_notes';
import ColoredSpan from '../components/helpers/text';
import Icon from '../components/helpers/icon';
import { getSeparatorColors } from '../components/helpers/colors';
import FullpageError from './fullpage_error';
import MobileHeader from './mobile_header';
import MobileNav from './mobile_nav';
import Logo from '../components/logo';
import LessonsList from '../components/bundletoc/lessons_list.jsx';

import ImmutablePropTypes from 'react-immutable-proptypes';
import { formatDuration, updateUrlParameter, layoutDirectionSuffix, isLayoutRTL } from 'lib/helpers';
import { Scrollbars } from 'react-custom-scrollbars';
import AttachedFiles from "app/components/lesson/attached_files";
import FacebookComments from '../components/lesson/fb_comments.jsx';
import CustomScrollBar from "react-scrollbars-custom";
import { Providers } from '../components/helpers/video_providers';
import { StudentTracker } from '../services/StudentTracker';
import LocalStorageManager from 'lib/localStorageManager';
import Cookies from 'js-cookie';
import Links from '../constants/links';
import SkipTo from '../components/skip_to';


class Lesson extends Container {
  static propTypes = {
    dispatch: PropTypes.func,

    allowMobile: PropTypes.bool,
    lessonContentAvailable: PropTypes.bool,
    lessons: ImmutablePropTypes.listOf(LessonProps),
    bundleName: PropTypes.string.isRequired,
    lessonName: PropTypes.string.isRequired,
    lessonId: PropTypes.number,
    video: VideoProps,
    mobileNotes: PropTypes.string,
    nextChapter: PropTypes.string,
    hideBottomPanel: PropTypes.bool,
    internalScroll: PropTypes.bool,
    className: PropTypes.string,
  }

  static defaultProps = {
    internalScroll: true,
    allowMobile: true,
    lessonContentAvailable: false,
    mobileNotes: false,
    lessons: Immutable.List(),
    hideBottomPanel: false,
    className: '',
  }

  constructor(props, ctx) {
    super(props, ctx);

    this.state = {
      showUserMenu: false,
      fullScreenMode: false,
      mobileNavOpen: false,
    };
  }

  componentDidMount(prevProps) {
    this.trackSigninEvent();
    if(this.props.purchaseDone){
      this.purchaseEvent(this.props);
    }
    this.trackStartCourse(this.props)
    window.MixpanelService.identify(this.props.teacherId);
    this.trackLessonView();
    if (this.props.admin && !this.props.inPreview) {
      this.props.dispatch(reportWarning('שים לב: הנך מחובר כמנהל הקורס וכל השיעורים פתוחים עבורך'));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lessonId === undefined && this.props.lessonId) {
      this.trackLessonView();
      this.setState({ mobileNavOpen: false });
    }
  }

  purchaseEvent = (props) => {
    if(LocalStorageManager.get('finalPrice') === null){
      return
    }
    StudentTracker.track('purchase', {
      'transaction_id': props.paymentId,
      'value': LocalStorageManager.get('finalPrice'),
      'currency': 'ILS',
      'items': this.itemProps(props)
    });
    LocalStorageManager.remove('couponName');
    LocalStorageManager.remove('couponDiscount');
    LocalStorageManager.remove('finalPrice');
  }

  itemProps = (props) => {
    let couponName = LocalStorageManager.get('couponName');
    let couponDiscount = LocalStorageManager.get('couponDiscount');
    let finalPrice = LocalStorageManager.get('finalPrice');
    let items = [
      {
        'item_id': `${props.bundleId}`,
        'item_name': props.bundleName,
        'item_brand': props.teacherName,
        'item_brand_id': props.teacherId,
        'item_category': props.schoolName,
        'item_category2': props.bundlePolicy,
        'coupon': couponName || props.couponName,
        'discount': couponDiscount || props.couponDiscount,
        'price': finalPrice || props.discountedPrice,
        'currency': "ILS",
        'quantity': 1
      }
    ];
    return items;
  }

  trackSigninEvent = () => {
    const loginByToken = Cookies.get('__sch_token_login');

    if(LocalStorageManager.get('signing_in') === true || loginByToken === 'true'){
      const { bundleid, bundleName, schoolId, schoolName, bundleType,
            teacherName, teacherId } = this.props;

      var method = 'email';
      if (loginByToken === 'true'){
        method = 'silent login';
        Cookies.remove('__sch_token_login')
      }
      StudentTracker.track('login', {
        'method': method,
        'item_id': bundleid,
        'item_name': bundleName,
        'item_brand': teacherName,
        'item_brand_id': teacherId,
        'item_category': schoolName,
        'item_category2': bundleType,
      });
      LocalStorageManager.remove('signing_in');
    }
  }

  trackStartCourse = (props) => {
    if(this.props.signedIn || this.props.freeForAll){
     let watched_count;
     if(this.props.watchedLessons !== undefined){
       watched_count = this.props.watchedLessons.size;
     }
      if(watched_count == 0 && (this.props.free || this.props.purchased)){
        StudentTracker.track('start_course', {
          'item_brand': props.teacherName,
          'item_brand_id': props.teacherId,
          'item_id': props.bundleid,
          'item_name': props.bundleName,
          'item_category': props.schoolName,
          'item_category2': props.bundleType,
        });
      }
    }
  }

  viewableLesson = () => {
    return this.props.lessonId && (this.props.free || this.props.purchased) && this.props.dripNotActive;
  }

  toggleMobileNav = (ev) => {
    this.setState((prevState) => ({ mobileNavOpen: !prevState.mobileNavOpen }));
  };

  hideSidebar = (ev) => {
    this.setState({ fullScreenMode: true });
    ev.preventDefault();
    ev.stopPropagation();
  };

  toggleSidebar = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ fullScreenMode: !this.state.fullScreenMode });
  };

  buyNow = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ showUserMenu: false });
    if (this.props.signedIn) {
      this.props.dispatch(cartActions.addBundleToCartAndNavigate(this.props.bundleid));
    } else {
      window.navigateTo(this.props.signupPath);
    }
  };

  clearErrors = () => {
    this.props.dispatch(clearError());
  };

  trackLessonView = () => {
    const { bundleid, bundleName, inPreview, lessonId, lessonName, lessonType, lessonSeq, currentUserId, schoolId,
            schoolName, selectedContentType, teacherName, teacherId, bundleType, sectionName, sectionSeq } = this.props;
    if(!inPreview && lessonId !== undefined){
      window.MixpanelService.track('Student watch lesson', {
        'Course ID': bundleid,
        'Course name': bundleName,
        'School ID': schoolId,
        'School name': schoolName,
        'Lesson ID': lessonId,
        'type of lesson': selectedContentType,
        'Student ID': currentUserId
      });

      StudentTracker.pageView({
        'item_brand': teacherName,
        'item_brand_id': teacherId,
        'item_id': bundleid,
        'item_name': bundleName,
        'item_category': schoolName,
        'item_category2': bundleType
      }, this.props.ga4DisabledEventsCodes);

      if(this.props.free || (this.props.signedIn && this.props.purchased)){
        StudentTracker.track(`lesson_${lessonSeq}_watch`, {
          'lesson_index_number': lessonSeq,
          'lesson_name': lessonName,
          'lesson_type': lessonType,
          'episode_index_number': sectionSeq,
          'episode_name': sectionName,
          'item_brand': teacherName,
          'item_brand_id': teacherId,
          'item_id': bundleid,
          'item_name': bundleName,
          'item_category': schoolName,
          'item_category2': bundleType
        });
      }
    }
  }

  arrowConvert = () => {
    const { fullScreenMode } = this.state;

    if (fullScreenMode) {
      return isLayoutRTL() ? 'arrow-left' : 'arrow-right';
    }

    return isLayoutRTL() ? 'arrow-right' : 'arrow-left';
  }


  isLessonBgTransparent() {
    const COMPONENTS_NAMES_REGEX = /AudioTab|VideoTab|PdfTab/;
    let componentNames = [];
    let isBgTransparent = true;

    if (this.props.children && React.Children.count(this.props.children) > 0) {
      componentNames = React.Children.map(this.props.children, child => child.type?.displayName || '')
      isBgTransparent = componentNames.some(name => !COMPONENTS_NAMES_REGEX.test(name))
    }

    return isBgTransparent;
  }
  onVisitCourse = (e) => {
    e.preventDefault();

    let path = window.location.origin;
    const href = this.props.bundlesIndexPath;
    if(href.indexOf('/s/') !== -1){
      const x = href.split('/s/');
      path = x[0];
      path += '/s/' + this.props.schoolId + '/bundles/#courseList';
    } else {
      path = '/bundles/#courseList'
    }
    window.location.href = path;
  }

  render() {
    const {
      bundlesIndexPath, error, facebookId, hasAttachedFiles, hasNotes, homepagePath, i18n, internalScroll, inTransition,
      selectedContentType, sidebarBgColor, textColor, video, warning} = this.props;

    const fgColor = textColor;
    const separatorColors = getSeparatorColors(sidebarBgColor);
    const mobileClassName = this.props.allowMobile ? 'allow-mobile' : 'hide-mobile';
    const userColors = {
      backgroundColor: sidebarBgColor,
      color: fgColor,
    };

    const showBottomPanel = (!this.props.hideBottomPanel) && hasNotes;
    const videoWrapper = classNames(`lesson-with-${selectedContentType}`, `lesson-with-${selectedContentType}-${inTransition}`, {
      'html5-player-preview': video && [Providers.WISTIA, Providers.VOO_PLAYER].includes(video.get('type')),
      [`player-preview-${video && video.get('type')}`]: video && video.get('type'),
      'middle-container video-responsive-wrap': internalScroll,
      'scrollable-middle-container': !internalScroll,
      'lesson-bg-transparent': this.isLessonBgTransparent(),
      'no-notes': !this.props.hideBottomPanel && !hasNotes && !hasAttachedFiles && !facebookId
    });

    const isRegistration = this.props.newUserButtonIcon === 'registration';
    const buttonText = isRegistration ? i18n.course.join : i18n.course.purchase;
    const buttonAriaLabel = isRegistration ? i18n.course.joinAriaLabel : i18n.course.purchaseAriaLabel;

    const AttachedFilesAndVideoNotesMobileComponent = (
      <div
        className={classNames("media-description", "hide-desktop", {
          "media-description--has-attachments": hasAttachedFiles,
          "media-description--has-notes": hasNotes,
        })}
      >
        <p className="media-description--header" style={userColors}>
          <cite className="media-description--header-title">
              {this.props.sectionName} | {this.props.lessonName}
          </cite>
          <time className="media-description--header-duration">
              {formatDuration(this.props.lessonDuration, false)}
          </time>
        </p>

          <AttachedFiles enableDropdown={true} userColors={userColors} />
        {hasNotes && <VideoNotes editable={false} userColors={userColors} />}
      </div>
    );

    return (
      <div className={'wrapper ' + this.props.className}>
        <SkipTo
          links={{
            main: i18n.general.skipToMainContent,
            navigation: i18n.general.skipToNavigation,
            ...(showBottomPanel && { notes: i18n.general.skipToNotesAndAttachment })
          }}
        />
        {this.props.allowMobile ?
          <div>
            <MobileHeader
              toggleMobileNav={this.toggleMobileNav}
              mobileNavOpen={this.state.mobileNavOpen}
              userColors={userColors}
              signinPath={(!this.props.signedIn && !this.props.freeForAll) ? this.props.signinPath : ''}
              signoutPath={this.props.signoutPath}
              buyNow={this.props.canbuy && this.buyNow}
              buyNowIcon={this.props.newUserButtonIcon}
              courseName={this.props.bundleName}
              teacherName={this.props.teacherName}
              videopdf={this.props.videopdf}
              nextLesson={this.props.nextChapter}
              prevLesson={this.props.prevChapter}
              bundlesIndexPath={bundlesIndexPath}
              i18n={i18n}
              vimeoOnIosDevice={this.props.vimeoOnIosDevice}
            />
            <MobileNav
              mobileNavOpen={this.state.mobileNavOpen}
              userColors={userColors}
              lessons={this.props.lessons}
              freeForAll={this.props.freeForAll}
              purchased={this.props.purchased}
            />
          </div>
          :
          <FullpageError text={ i18n.noMobileSupport } className='show-mobile' />
          }

          <Scrollbars
            universal={true}
            style={{direction: 'ltr'}}
          >
            <div
              className={`content ${mobileClassName} ${this.state.fullScreenMode ? 'content-fullscreen' : ''}  ${this.props.vimeoOnIosDevice ? 'vimeo-on-ios' : ''}`}
              style={{direction: 'rtl'}}
            >
              {error &&
                <div className="my-notification my-notification-danger">
                  <div className="icon-n-text">
                    <span className="icon icon-danger"></span>
                    <span className="text">{error}</span>
                  </div>
                  <div className="close" onClick={this.clearErrors}>×</div>
                </div>
              }

              {warning &&
              <div className="my-notification my-notification-warning">
                <div className="icon-n-text">
                  <span className="icon icon-danger"></span>
                  <span className="text">{warning}</span>
                </div>
                <div className="close" onClick={this.clearErrors}>×</div>
              </div>
              }
              <div
                className="top-panel"
                role="banner"
                aria-label={i18n.system.bannerAriaLabel}
                style={userColors}
              >
                <div className={`top-panel-heading truncate-text ${this.props.schoolCoverImage ? 'with-school-logo' : ''}`}>
                  <strong>{this.props.bundleName}</strong>{this.props.lessonName && <span> - {this.props.lessonName}</span>}
                  <div
                    className="hide-side-menu nm-tooltiped"
                    data-toggle="tooltip"
                    data-placement="right"
                    onClick={this.toggleSidebar}
                  >

                    <Icon name={this.arrowConvert()} />
                    <div className="nm-tooltip">
                      <div className="tooltip-inner" aria-label={i18n.lesson.misc.fullScreenAriaLabel} dangerouslySetInnerHTML={{
                        __html: i18n.lesson.misc.fullScreen
                      }}></div>
                    </div>
                  </div>
                </div>

                {this.props.nextChapter &&
                  <a
                    href={this.props.nextChapter}
                    className="button button-prev"
                    accessKey="n"
                    style={{
                      backgroundColor: this.props.sidebarBgColor,
                        color: fgColor,
                    }}
                  >
                    <span className='button-text' dangerouslySetInnerHTML={{
                      __html: i18n.lesson.misc.next
                    }}></span>
                    <Icon name={layoutDirectionSuffix('arrow', true)} />
                  </a>
                }
                <ul className="top-panel-menu main-top">
                  { bundlesIndexPath &&
                      <li>
                        <a href='#' onClick={this.onVisitCourse}>
                          <span className="icon-n-text" data-testname='top-link-all-courses'>
                            <ColoredSpan className={`icon sch-${this.props.newUserButtonIcon}`} aria-hidden="true"></ColoredSpan>
                            <ColoredSpan className="text" dangerouslySetInnerHTML={{
                              __html: i18n.school.allCourses
                            }}></ColoredSpan>
                          </span>
                        </a>
                      </li>
                  }
                  {this.props.inPreview &&
                    <li className='nm-tooltiped'>
                      <a
                        href={this.props.leavePreviewPath}
                        data-testname='leave-preview'
                      >
                        <Icon name='edit' />
                      </a>
                      <div className="nm-tooltip">
                        <div className="tooltip-inner">
                          {i18n.system.lesson.returnToEditMode}
                        </div>
                      </div>
                    </li>
                  }
                  {this.props.canbuy &&
                      <li>
                        <a href="#" onClick={this.buyNow} aria-label={buttonAriaLabel}>
                          <span className="icon-n-text handle-formation" data-testname='new-user-button'>
                            <ColoredSpan className={`icon sch-${this.props.newUserButtonIcon}`} aria-hidden="true"></ColoredSpan>
                            <ColoredSpan className="text">{buttonText}</ColoredSpan>
                          </span>
                        </a>
                      </li>
                  }
                  {
                    !this.props.freeForAll && !this.props.inPreview && (
                      this.props.signedIn
                      ?  (
                        <li>
                          <a href={this.props.signoutPath} data-testname="signout-button" aria-label={i18n.general.logoutAriaLabel}>
                            <span className="icon-n-text handle-formation">
                              <Icon className='icon' name='exit' />
                              <ColoredSpan className='text' dangerouslySetInnerHTML={{
                                __html: i18n.general.logout
                              }}></ColoredSpan>
                            </span>
                          </a>
                        </li>
                      )
                      :  (
                        <li>
                          <a href={this.props.signinPath} aria-label={i18n.general.loginAriaLabel}>
                            <span className="icon-n-text handle-formation">
                              <Icon className='icon' name='connect' />
                              <ColoredSpan className='text' data-testname='signin-button' dangerouslySetInnerHTML={{
                                __html: i18n.general.login
                              }}></ColoredSpan>
                            </span>
                          </a>
                        </li>
                      )
                    )
                  }
                </ul>
              </div>

              { // Show the attached files and video notes on mobile
                this.props.lessonContentAvailable &&
                this.props.mobileNotes === 'top' && (
                  AttachedFilesAndVideoNotesMobileComponent
                )
              }

              <div className={videoWrapper}>
                <div className={classNames({
                  'video-responsive': this.props.internalScroll,
                  'disable-aspect-ratio': selectedContentType === 'audioAttachment',
                })}>
                  <div className='lesson--content_object' role='main' id='main' aria-label={i18n.course.lessonContentAriaLabel}>
                    {this.props.children}
                  </div>
                </div>
              </div>

              { // Show the attached files and video notes on mobile
                this.props.lessonContentAvailable &&
                this.props.mobileNotes === 'bottom' && (
                  AttachedFilesAndVideoNotesMobileComponent
                )
              }

              {showBottomPanel &&
                <div className="bottom-panel hide-mobile" id="notes" role="complementary" aria-label={i18n.lesson.misc.courseNoteAriaLabel}>
                  <div className='text side-line'>
                    <VideoNotes editable={false} userColors={userColors} />
                  </div>
                </div>
              }

              { // Show the attached files on desktop
                this.props.lessonContentAvailable && (
                <AttachedFiles className="hide-mobile" userColors={userColors} />
                )
              }

              <FacebookComments />
            </div>
          </Scrollbars>
          <div
            className={`sidebar ${mobileClassName} ${this.state.fullScreenMode ? 'sidebar-hidden' : ''}`}
            style={{ backgroundColor: this.props.sidebarBgColor }}
          >
            <div className='sidebar_wrapper'>
              <div className='sidebar-top'>
                {this.props.schoolCoverImage &&
                  <a href={homepagePath}>
                    <img
                      src={this.props.schoolCoverImage}
                      className='course-image'
                      height="70"
                      max-width="300"
                      alt="course poster"
                      style={{padding: '10px'}}
                    />
                  </a>
                }

                <img
                  src={this.props.bundleImage}
                  className='course-image'
                  width="300"
                  height="160"
                  alt="course poster"
                />
                <div
                  className="course-description"
                  style={Object.assign({}, {
                    borderColor: separatorColors,
                  }, userColors)}
                >
                  <div className="name">
                    {this.props.bundleName}
                  </div>
                  <div className="author" dangerouslySetInnerHTML={{
                    __html: i18n.lesson.misc.by.replace('%{teacher_name}', this.props.teacherName)
                  }}></div>
                </div>
              </div>
              <CustomScrollBar noScrollX disableTrackXWidthCompensation
                trackYProps={{
                  renderer: props => {
                    const { elementRef, ...restProps } = props;
                    return <span {...restProps} ref={elementRef} className="trackY" />;
                  }
                }}
              >
                <div className='aside-menu compact' role='navigation' id='navigation' aria-label={i18n.lesson.misc.navigationAriaLabel}>
                  <SidebarToc>
                    <LessonsList
                      lessons={this.props.lessons}
                      sidebarLayout={true}
                      bgColor={this.props.sidebarBgColor}
                    />
                  </SidebarToc>
                </div>
              </CustomScrollBar>
              <footer className="sidebar-bottom credits" aria-label={i18n.creditAriaLabel} style={userColors} >
                {this.props.hasA11yStatement && !this.props.isExplicitSchool ? (
                  <>
                    <div aria-hidden="true"><Logo color={fgColor} /></div>
                    <div className="links-container">
                      <a
                        href={Links.SCHOOLER_HOMEPAGE_URL}
                        target="_blank"
                        className="top-link"
                      >
                        {i18n.credit}
                      </a>
                      <a
                        href={this.props.previewBundleStatementPath}
                        className="bottom-link"
                        target="_blank"
                      >
                        {i18n.a11yStatement}
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    <div aria-hidden="true"><Logo color={fgColor} /></div>
                    <a
                      href={Links.SCHOOLER_HOMEPAGE_URL}
                      target="_blank"
                      className="text"
                      style={{
                        borderColor: fgColor,
                      }}
                    >
                      {i18n.credit}
                    </a>
                  </>
                )}
              </footer>
            </div>
          </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const lessonId   = state.$$lesson.get('id');
  const lessonIdx  = lessonId ? state.$$bundletoc.get('lessons').findIndex((l) => l.get('lesson_id') === lessonId) : -1;
  const lessonData = lessonId ? state.$$bundletoc.getIn(['lessons', lessonIdx]) : Immutable.Map();
  const editLessonData = state.teacher.editLesson.get('lessonNameData');
  const lessonName = (editLessonData ? editLessonData.name : lessonData.get('lesson_name')) || '';
  const bundleId = state.$$bundletoc.get('bundleid');
  const previewBundleStatementPath = state.$$bundletoc.get('preview_bundle_statement');
  const totalLessons = state.$$bundletoc.get('lessons');
  const hasA11yStatement = state.$$bundletoc.get('has_a11y_statement');
  const isExplicitSchool = state.$$school.get('explicit');

  let watchedLessons;
  if (totalLessons !== undefined){
    watchedLessons = totalLessons.filter((lesson) => lesson.get('watched') === true);
  }
  const inTransition = state.$$nav.get('inTransition') ? 'in-transition' : 'out-transition';

  const i18n = {
    credit: state.$$i18n.getIn(['system', 'credit']),
    a11yStatement: state.$$i18n.getIn(['system', 'a11yStatement']),
    creditAriaLabel: state.$$i18n.getIn(['system', 'creditAriaLabel']),
    noMobileSupport: state.$$i18n.getIn(['system', 'noMobileSupport']),
    general: state.$$i18n.getIn(['students', 'general']).toJS(),
    course: state.$$i18n.getIn(['students', 'course']).toJS(),
    lesson: state.$$i18n.getIn(['students', 'lesson']).toJS(),
    school: state.$$i18n.getIn(['students', 'school']).toJS(),
    newUserButtonText: state.$$i18n.get('new_user_button_text'),
    system: state.$$i18n.getIn(['system']).toJS(),
  };

  return {
    i18n,
    inTransition,
    hasA11yStatement,
    previewBundleStatementPath,
    isExplicitSchool,
    newUserButtonIcon: state.$$layout.getIn(['default_bundle_props', 'paid_for']) ? 'cart' : 'registration',
    homepagePath: state.$$layout.getIn(['urls', 'root']),
    username: state.$$account.get('currentUsername'),
    showSelectContentPopup: state.teacher.editLesson.get('selectContentPopupVisible'),
    bundleName: state.$$bundletoc.get('name'),
    bundleType: state.$$bundletoc.get('course_type'),
    teacherName: state.$$bundletoc.get('teacher_name'),
    bundleid: bundleId,
    open: lessonData.get('open'),
    sectionName: lessonData.get('section_name'),
    lessonDuration: lessonData.get('duration'),
    lessonType: lessonData.get('type'),
    lessonSeq: lessonData.get('lesson_seq'),
    sectionSeq: lessonData.get('section_seq'),
    vimeoOnIosDevice: lessonData.get('vimeo_on_ios'),
    lessons: state.$$bundletoc.get('lessons') || Immutable.List(),
    watchedLessons,
    lessonName,
    lessonId: lessonId,
    video: state.$$lesson.get('video'),
    videopdf: state.$$lesson.getIn(['video', 'pdf', 'url']),
    nextChapter: state.$$lesson.get('next'),
    prevChapter: state.$$lesson.get('prev'),
    free: state.$$lesson.get('free') || false,
    contentTypes: state.$$lesson.get('contentTypes'),
    pdfurl: state.$$lesson.get('pdfurl'),
    iframesrc: state.$$lesson.get('iframesrc'),
    signinPath: state.$$layout.getIn(['urls', 'login']),
    signoutPath: state.$$layout.getIn(['urls', 'logout']),
    signedIn: state.$$account.get('signedIn'),
    myaccount: state.$$layout.getIn(['urls', 'myaccount']),
    // bundleId is added to shopping cart after signup in SchoolRegistrationHelper
    signupPath: updateUrlParameter(state.$$layout.getIn(['urls', 'signup']), 'bundle', bundleId),
    canbuy: state.$$account.get('canbuy') && !state.$$bundletoc.get('free_for_all'),
    bundleImage: state.$$bundletoc.get('image'),
    schoolCoverImage: state.$$layout.get('school_logo'),
    sidebarBgColor: state.$$theme.getIn(['settings', 'background_color']),
    textColor: state.$$theme.getIn(['settings', 'text_color']),
    inPreview: !!state.$$layout.get('preview'),
    leavePreviewPath: state.$$layout.getIn(['urls', 'leave_preview']),
    error: state.$$layout.getIn(['flash', 'text']) || state.$$layout.get('jserror') || '',
    warning: state.$$layout.getIn(['flash', 'warning']) || state.$$layout.get('jswarning') || '',
    freeForAll: state.$$bundletoc.get('free_for_all'),
    admin: state.$$account.get('admin'),
    currentUserId: state.$$account.get('currentUserId'),
    hasNotes: !!state.$$lesson.get('notes'),
    hasAttachedFiles: !!state.$$lesson.get('filesPanel'),
    facebookId: state.$$lesson.getIn(['comments', 'fbid']),
    bundlesIndexPath: state.$$layout.getIn(['urls', 'school_home']),
    purchased: state.$$layout.getIn(['default_bundle_props', 'owned']),
    dripNotActive: !state.$$lesson.getIn(['drip', 'active']),
    schoolId: state.$$layout.get('school_id'),
    schoolName: state.$$layout.get('school_name'),
    teacherId: state.$$layout.getIn(['storageapi_id']),
    ga4DisabledEventsCodes: state.$$bundletoc.get('ga4_disabled_events_codes'),
    couponDiscount: state.$$afterjoin.get('coupon_discount'),
    purchaseDone: state.$$afterjoin.get('purchase_done'),
    paymentId: state.$$afterjoin.get('payment_id'),
    bundlePrice: state.$$afterjoin.get('bundle_price') || 0,
    bundlePolicy: state.$$afterjoin.get('bundle_policy'),
    couponName: state.$$afterjoin.get('coupon_name'),
    discountedPrice: state.$$afterjoin.get('discounted_price')
  };
}

export default connect(mapStateToProps)(Lesson);


import React, { useState, useEffect, PropTypes } from 'react';
import { connect } from 'react-redux';
import { clearError } from '../actions/layout';

const FlashMessage = ({ message, type, onClose }) => {
  const [visible, setVisible] = useState(!!message);

  useEffect(() => {
    setVisible(!!message);
  }, [message]);

  if (!visible) return null;

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  let backgroundColorClass, borderColorClass;

  switch (type) {
    case 'success':
      backgroundColorClass = 'bg-success';
      borderColorClass = 'border-success';
      break;
    case 'error':
      backgroundColorClass = 'bg-error';
      borderColorClass = 'border-error';
      break;
    case 'warning':
      backgroundColorClass = 'bg-warning';
      borderColorClass = 'border-warning';
      break;
  }

  return type === 'info' ? (
    <div className="w-full py-2.5 bg-cyan-400 justify-center items-center inline-flex flash-info-bar md:top-[56px] top-0 absolute z-50">
      <div className="text-center text-zinc-800 text-sm font-normal font-['Assistant'] leading-normal px-12 directional">{message}</div>
      <button className="flash-close !text-base border-none absolute fm-close !bg-transparent" onClick={handleClose}>
        &times;
      </button>
    </div>
  ) : (
    <div className="flash-container md:mt-20 mt-12">
      <div className={`flash-message flash-${type} border-y border-l border-r-[7px] border-solid rounded-[6px] w-[335px] py-[14px] px-2 ${borderColorClass}`}>
        <div className="flex justify-between gap-4 directional">
          <div className={`h-6 w-6 rounded-full ${backgroundColorClass} p-2 pt-1`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 9 9" fill="none">
              <g clipPath="url(#clip0_9302_90366)">
                <path d="M8.79088 1.47102C8.51241 1.19219 8.06024 1.19236 7.7814 1.47102L3.23808 6.01452L1.21877 3.99523C0.939939 3.71639 0.487957 3.71639 0.209124 3.99523C-0.0697081 4.27406 -0.0697081 4.72604 0.209124 5.00487L2.73315 7.5289C2.87248 7.66823 3.05518 7.73807 3.23789 7.73807C3.4206 7.73807 3.60347 7.66841 3.7428 7.5289L8.79088 2.48065C9.06971 2.20201 9.06971 1.74984 8.79088 1.47102Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_9302_90366">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="flex gap-2">
            <div className="directional text-justify tracking-tighter">
              <p className='font-bold text-lg m-0'>Password</p>
              <p className='text-lg text-[#333] m-0'>{message}</p>
            </div>
            <div className="self-start">
              <button className={`flash-close border-none !bg-transparent leading-none !text-[30px]`} onClick={handleClose}>
                &times;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FlashMessage.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  onClose: PropTypes.func.isRequired
};

FlashMessage.defaultProps = {
  message: '',
  type: 'info'
};

const mapStateToProps = (state) => ({
  message: state.$$layout.getIn(['flash', 'message']),
  type: state.$$layout.getIn(['flash', 'type'])
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(clearError())
});

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessage);

import React from 'react';
import { connect } from 'react-redux';
import userNameHelper from 'lib/form_helpers';
import { Input } from './form_for';
import LocalStorageManager from 'lib/localStorageManager';
import LoginFormLogo from '../../components/svg/login_form_logo';

SchoolLoginForm.propTypes = {
  token: React.PropTypes.string,
  schoolId: React.PropTypes.number,
  loginUrl: React.PropTypes.string,
  passwordResetUrl: React.PropTypes.string,
  customDomain: React.PropTypes.bool.isRequired,
  embeddedForm: React.PropTypes.bool,
};

function setLoginKey(){
  LocalStorageManager.save('signing_in', true);
}

function SchoolLoginForm(props) {
  const name = userNameHelper(props.customDomain);

  return (
    <div className="content-inner">

      <div className="iconed-block">
        <div className="iconed-block-inner">

          <div className="col-set col-set-bordered school-login-form-direction school-login-form">
            <div className="col">
              <div className="inline-block">
                <LoginFormLogo fillColor={props.altColor} />
              </div>
              <h3 className='new-user-form-header' dangerouslySetInnerHTML={{ __html: props.i18n.loginTitle }}></h3>

              <form
                role="form"
                action={props.loginUrl}
                method="POST"
                className='nm-course-form clear course-signin-form'
                onSubmit={setLoginKey}
              >
                <input name="authenticity_token" type="hidden" value={props.token} />
                <input type="hidden" name={name('school_id')} value={props.schoolId} />

                <div className="nm-form-row distance-sm">
                  <div className='nm-left'>
                    <Input
                      className="nm-input input-email"
                      customValidity={props.i18n.emailError}
                      id="email-field"
                      name={name('email')}
                      aria-label={props.i18n.emailAriaLabel}
                      placeholder={props.i18n.email}
                      required={true}
                      type="email"
                    />
                  </div>
                </div>

                <div className="nm-form-row distance-sm">
                  <div className='nm-left'>
                    <input
                      name={name('password')}
                      type="password"
                      className="nm-input input-password"
                      id="password-field"
                      autoComplete="off"
                      aria-label={props.i18n.passwordAriaLabel}
                      placeholder={props.i18n.password}
                    />
                  </div>
                </div>

                <div className="small">
                  <a className='forgot-password-link school-login-password' aria-label={props.i18n.forgotPasswordAriaLabel} href={props.passwordResetUrl} dangerouslySetInnerHTML={{
                    __html: props.i18n.forgotPassword
                  }}></a>
                </div>

                <div className='signin-button'>
                  <input type='submit' className='nm-btn' aria-label={props.i18n.loginAriaLabel} value={props.i18n.loginButton} style={{color: props.textColor, backgroundColor: props.altColor}} />
                </div>

                <div className='nm-login-actions distance-sm signup-suggestion-block link-to-registration school-login-register' dangerouslySetInnerHTML={{
                  __html: props.i18n.schoolNewUserLink.replace('%{target_link}', props.signupUrl)
                }}></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    canbuy: state.$$account.get('canbuy'),
    customDomain: state.$$layout.get('customDomain'),
    i18n: state.$$i18n.getIn(['students', 'form']).toJS(),
    loginUrl: state.$$layout.getIn(['urls', 'login']),
    passwordResetUrl: state.$$layout.getIn(['urls', 'reset_password']),
    schoolId: state.$$layout.get('school_id'),
    signupUrl: state.$$layout.getIn(['urls', 'signup']),
    textColor: state.$$school.getIn(['template_values', 'textColor', 'value']),
    altColor: state.$$school.getIn(['template_values', 'secondaryColor', 'value']),
    templateName: state.$$school.get('template_name'),
    token: state.$$layout.get('authenticityToken'),
  };
}

export default connect(mapStateToProps)(SchoolLoginForm);

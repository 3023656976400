import React, { useEffect } from 'react';
import { adjustHeaderFontSize, scrollToElement } from '../../../components/helpers/school';

const UseEffectInitializer = () => {

  useEffect(() => {
    // Display small size header text in mobile.
    if (window.innerWidth < 576) {
      adjustHeaderFontSize('.school-grid.school-header-text-container .school-header *');
      adjustHeaderFontSize('.school-container .row.header *'); // default template
      adjustHeaderFontSize('.school-container .row.subheader *');
    }

    scrollToElement('.school-signup-form');
    scrollToElement('.school-login-form');
    scrollToElement('.school-new-pass-form');
  }, []);

  return null;
};

export default UseEffectInitializer;

import React from 'react';
import Immutable from 'immutable';
import { formatDuration } from 'lib/helpers';

let _chapter = null;
function newChapter(lesson) {
  if (lesson.get('section_id') !== _chapter) {
    _chapter = lesson.get('section_id');
    return true;
  }
  return false;
}

function iconForLesson(lesson) {
  if (lesson.get('type') === 'video') {
    return 'icon-video';
  } else if (lesson.get('type') === 'iframe') {
    return 'icon-www';
  } else if (lesson.get('type') === 'pdf') {
    return 'icon-pdf';
  } else if (lesson.get('type') === 'quiz') {
    return 'sch-test';
  }

  return '';
}

export default function MobileNav(props) {
  const { lessons, freeForAll, purchased } = props;

  let lessonsWithChapters = Immutable.List();
  let chapterIndex = 1;

  for (let i = 0; i < lessons.size; i++) {
    if (newChapter(lessons.get(i))) {
      lessonsWithChapters = lessonsWithChapters.push(Immutable.fromJS({
        type: 'chapter',
        name: lessons.getIn([i, 'section_name']),
        index: chapterIndex++,
        firstLessonPath: lessons.getIn([i, 'path']),
      }));
    }
    lessonsWithChapters = lessonsWithChapters.push(lessons.get(i));
  }

  return (
    <nav className={`hide-desktop nav-mobile ${props.mobileNavOpen ? 'open' : ''}`}>
      <div className="inner">

        <div className="menu-mobile">
          <ul>
            {lessonsWithChapters.map((lesson, lessonId) => (
              lesson.get('type') === 'chapter' ?
              <li className="menu-item highlight" key={lessonId}>
                <span className="menu-item-icon">
                  <span className="icon icon-course-number-2">{lesson.get('index')}</span>
                </span>
                <a href={(lesson.get('path'))}>
                  <span className="caption">{lesson.get('name')}</span>
                </a>
              </li> :
              <li className="menu-item muted" key={lessonId}>
                <span className="menu-item-icon">
                  <span className={`icon ${iconForLesson(lesson)} `}></span>
                </span>

                <span className="permission">
                 {!freeForAll && !purchased && lesson.get('open') && <span className='sch-preview'></span>}
                </span>

                <a href={(lesson.get('path'))}>
                  <span className="caption">{lesson.get('lesson_name')}</span>
                </a>

                <span className="time">{formatDuration(lesson.get('duration'), false)}</span>
                <span className={`icon ${lesson.get('watched') ? 'icon-tick' : ''}`}></span>
              </li>
            ))}

          </ul>
        </div>

      </div>
    </nav>
  );
}


import $ from 'jquery';

import { symbols as navSymbols } from './ajax';

export const symbols = {
  ERROR: '@@bundle_join.error',
};

export function startTrial (bundleId) {
  return (dispatch, getstate) => {
    if (getstate().$$account.getIn(['signedIn'])) {
      dispatch(startTrialForCurrentUser(bundleId));
    } else {
      dispatch(signupAndStartTrial(bundleId));
    }
  };
}

function startTrialForCurrentUser (bundleId) {
  return (dispatch, getstate) => {
    const startTrialUrl = getstate().$$layout.getIn(['urls', 'start_trial']);
    $.ajax({
      type: "POST",
      url: startTrialUrl,
      data: { bundle_id: bundleId },
      dataType: "json",
    }).then(function (res) {
      dispatch({ type: navSymbols.APPSTATE_MERGE, payload: res });
      window.scrollTo(0,0);
    }).fail(function (err) {
      dispatch({ type: symbols.ERROR, payload: err });
    });
  };
}

function signupAndStartTrial (bundleId) {
  return (dispatch, getstate) => {
    window.navigateTo(`/signup?trybundle=${bundleId}`);
  };
}

import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Form, CheckBoxWithLabel, Input } from '../components/forms/form_for';
import Layout from './join_bundle_layout';
import {ConditionalComponent} from '../components/common';
import withJoin from '../components/HOC/withJoin';
import { StudentTracker } from '../services/StudentTracker';

function instructionsCourseInSchool(props) {
  const instructionsParagraph = props.i18n.schoolInstructions[
    props.finalPrice > 0 ? 'paidParagraph' : 'registrationParagraph'
  ];

  const title = props.i18n.schoolInstructions[
    props.finalPrice > 0 ? 'paidTitle' : 'title'
  ];

  return (
    <div>
      <h3 className='new-user-form-header'
        dangerouslySetInnerHTML={{__html: title.replace('%{school_name}', props.schoolName)}}>
      </h3>

      <div style={{margin: '10px 0 30px 0'}}
        dangerouslySetInnerHTML={{__html: instructionsParagraph}}>
      </div>
    </div>
  );
}

function instructionsStandaloneCourse(props) {
  return (
    <h3 className='new-user-form-header'
      dangerouslySetInnerHTML={{__html: props.i18n.courseInstructions.title}}>
    </h3>
  );
}

const JoinCourseHeader = connect(mapStateToProps)(function (props) {
  const joinCourseTitle = props.i18n[props.price > 0 ? 'paidTitle' : 'title']
    .replace('%{course_name}', props.bundleName);

  const joinCourseParagraph = props.i18n[
    props.price > 0 ? 'paidParagraph' : 'registrationParagraph'
  ];

  return (
    <section className="padding-right-10">
      <div>
        <h3 className="join-course-title" dangerouslySetInnerHTML={{__html: joinCourseTitle}}></h3>

        {!!props.discount &&
          <p className="sub-heading-2"
            data-testname="price-before-discount"
            dangerouslySetInnerHTML={{
              __html: props.i18n.priceWithDiscount
                .replace('%{price}', props.price)
                .replace('%{discount_price}', props.discount)
            }}></p>}


        {props.price > 0 && (
          <h3 className="color-brand" data-testname="price-after-discount">
            <span class="remove-uppercase">
              <img src="/images/tag.svg" className="padding-10" />
              {props.i18n.price.replace("%{price}", props.finalPrice)}
            </span>
          </h3>
        )}

        <p className="sub-heading-2"
          dangerouslySetInnerHTML={{__html: joinCourseParagraph}}></p>

        <br />
      </div>
    </section>
  );
});

const JoinCourseMainContent = connect(mapStateToProps)(withJoin(class extends React.Component {

  componentDidMount(prevProps) {
    StudentTracker.pageView( this.props.eventProps, this.props.ga4DisabledEventsCodes);
  }

  render(){
    const props = this.props;
    const isPaymentRequired = props.price > 0;

    const i18nFormSubmitButton = props.i18n.form[isPaymentRequired ? 'payButton' : 'registerButton'];
    const i18nFormSubmitButtonAriaLabel = props.i18n.form[isPaymentRequired ? 'payButtonAriaLabel' : 'registerButtonAriaLabel'];

    return (
    <div className="col course-reg">

      {
        !!props.schoolName.length ? instructionsCourseInSchool(props) : instructionsStandaloneCourse(props)
      }

      <Form for="user" action="new_school_user_registration" method="POST" className='form-simple'>
        <Input type="hidden" name="bundleid" value={props.bundleId} />
        {
          props.showNameField &&
          <Input
            className="nm-input input-user"
            name='name'
            type="text"
            aria-label={props.i18n.form.fullNameAriaLabel}
            placeholder={props.i18n.form.fullName}
            defaultValue={props.name}
            required={props.nameRequired}
            customValidity={props.i18n.form.fullNameError}
          />
        }
        <Input
          className="nm-input input-email"
          name='email'
          required={true}
          type="email"
          aria-label={props.i18n.form.emailAriaLabel}
          placeholder={props.i18n.form.email}
          defaultValue={props.email}
          customValidity={props.i18n.form.emailError}
        />

        {
          props.showPhoneField &&
            <Input
              className="nm-input input-phone"
              name='phone'
              type="tel"
              aria-label={props.i18n.form.phoneAriaLabel}
              placeholder={props.i18n.form.phone}
              defaultValue={props.phone}
              pattern={props.i18n.form.phonePattern}
              required={props.phoneRequired}
              customValidity={props.i18n.form.phoneError}
            />
        }

        {
          props.bundle &&
            <input type='hidden' name='initial_bundle_id' value={props.bundle} />
        }

        {
          props.couponId &&
          <input type='hidden' name='coupon_id' value={props.couponId} />
        }
        <ConditionalComponent isRender={!isEmpty(props.terms)}>
          <CheckBoxWithLabel className="terms-course-wrapper" name="is_terms_approved" label={props.terms} onClick={props.termLinkClick} testName="terms" ariaLabel={props.i18n.termsAndConditionAriaLabel} />
        </ConditionalComponent>

        <ConditionalComponent isRender={props.isTermsNotApproved}>
          <div className="feedback-invalid" dangerouslySetInnerHTML={{__html: props.i18n.form.termsError }}></div>
        </ConditionalComponent>

        <ConditionalComponent isRender={props.getFlushContainerErrors() !== ''}>
          <div className="feedback-invalid">{props.getFlushContainerErrors().split(',').map(error=>(<div>{error}</div>))}</div>
        </ConditionalComponent>

        <div className="form-actions">
          <div className='link-to-signin'
            dangerouslySetInnerHTML={{__html: props.i18n.form.loginLink.replace('%{target_link}', props.loginPath) }}>
          </div>

          <button
            className="button button-primary remove-uppercase"
            onClick={props.validateBeforeSubmit}
            aria-label={i18nFormSubmitButtonAriaLabel}
            dangerouslySetInnerHTML={{ __html: i18nFormSubmitButton }}
          ></button>
        </div>

      </Form>
    </div>
    );
  }
}))

function JoinCourse() {
  return (
    <Layout
      Header={JoinCourseHeader}
      Content={JoinCourseMainContent}
    />
  );
}

function mapStateToProps(state) {
  const price = Number(state.$$layout.getIn(['default_bundle_props', 'price']) || 0);
  const discount = Number(state.$$layout.getIn(['default_bundle_props', 'discount']) || 0);
  let prefillData = {};
  try {
    prefillData = JSON.parse(state.$$account.get('prefill_data'));
  } catch (err) {
    // pass
  }

  return {
    newUserButtonIcon: state.$$layout.getIn(['default_bundle_props', 'paid_for']) ? 'cart' : 'registration',
    loginPath: state.$$layout.getIn(['urls', 'login']),
    bundleId: state.$$layout.getIn(['default_bundle_props', 'id']),
    bundleName: state.$$layout.getIn(['default_bundle_props', 'name']),
    email: prefillData.email ? decodeURIComponent(prefillData.email) : '',
    phone: prefillData.phone ? decodeURIComponent(prefillData.phone) : '',
    name: prefillData.name ? decodeURIComponent(prefillData.name) : '',
    instructor: state.$$layout.getIn(['default_bundle_props', 'instructor']).replace(/\n/g, '<br />'),
    audience: state.$$layout.getIn(['default_bundle_props', 'audience']).replace(/\n/g, '<br />'),
    description: state.$$layout.getIn(['default_bundle_props', 'description']).replace(/\n/g, '<br />'),
    price: state.$$layout.getIn(['default_bundle_props', 'price']),
    discount: state.$$layout.getIn(['default_bundle_props', 'discount']),
    finalPrice: price - discount,
    phoneRequired: state.$$layout.getIn(['fields', 'phone']) === 'required',
    nameRequired: state.$$layout.getIn(['fields', 'name']) === 'required',
    showPhoneField: state.$$layout.getIn(['fields', 'phone']) !== 'hidden',
    showNameField:  state.$$layout.getIn(['fields', 'name']) !== 'hidden',
    schoolName: state.$$school.get('name'),
    termsAttachmentUrl: state.$$layout.getIn(['urls', 'terms_attachments']),
    terms : state.$$layout.get('terms'),
    couponId : state.$$layout.get('coupon_id'),
    customDomain: state.$$layout.get('customDomain'),
    ga4DisabledEventsCodes: state.$$layout.get('ga4_disabled_events_codes'),
    eventProps: state.$$layout.get('event_props').toJS(),
    i18n: {
      ...state.$$i18n.getIn(['students', 'joinCourse']).toJS(),
      form: state.$$i18n.getIn(['students', 'form']).toJS()
    }
  };
}

export default connect(mapStateToProps)(JoinCourse);

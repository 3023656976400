import { unescape } from 'lodash';

export const FILE_NAME_WITH_EXTENSION = /\/([^\/?#]+)[^\/]*$/
export function formatDuration(duration, isDisplayMinutesLegend = true) {
  if (!(duration > 0)) {
    return '';
  }

  const hours = Math.floor(duration / 3600).toString().padStart(2, '0');
  const minutes = Math.floor((duration % 3600) / 60).toString().padStart(2, '0');
  const seconds = Math.floor(duration % 60).toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds} ${isDisplayMinutesLegend ? 'ד\'' : ''} `;
}

// Add / Update a key-value pair in the URL query parameters
export function updateUrlParameter(uri, key, value) {
  // remove the hash part before operating on the uri
  var i = uri.indexOf('#');
  var hash = i === -1 ? ''  : uri.substr(i);
  uri = i === -1 ? uri : uri.substr(0, i);

  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf('?') !== -1 ? "&" : "?";
  if (uri.match(re)) {
    uri = uri.replace(re, '$1' + key + "=" + value + '$2');
  } else {
    uri = uri + separator + key + "=" + value;
  }
  return uri + hash;  // finally append the hash as well
}

export function mobileAndTabletcheck() {
  return ['mobile', 'tablet'].includes(window.USER_DEVICE?.type || '');
}

export const format = (format, ...params) => {
  return format.replace(/{(\d+)}/g, (match, number) => {
    return typeof params[number] !== 'undefined' ? params[number] : match;
  });
};

export const getQueryParameterByName = (name, url = window.location.search) => {
  const match = RegExp(`[?&]${name}=([^&]*)`).exec(url);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

export const getBase64FromPath = (imgUrl) => {
  return fetch(imgUrl)
  .then((response) =>response.arrayBuffer())
  .then(async (arrayBuffer) => {
    return await new Promise((resolve, reject) => {
      const blob = new Blob([new Uint8Array(arrayBuffer)], { type: 'image/png' });
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.addEventListener('load', () => resolve(reader.result))
    })
  })
}

export const getFileApiFromUrl = (imgUrl, type = 'application/pdf', extractFileName = true) => {
  return fetch(imgUrl)
  .then((response) =>response.arrayBuffer())
  .then(async (arrayBuffer) => {
    return await new Promise((resolve, reject) => {
      let fileName = 'temp.pdf'
      if(extractFileName){
        const fName = imgUrl.match(FILE_NAME_WITH_EXTENSION)
        fileName = fName && fName.length > 1 ? fName[1] : fileName
      }
      const blob = new Blob([new Uint8Array(arrayBuffer)]);
      const file = new File([blob], fileName, { type })
      resolve(file)
    })
  })
  .catch(() => console.error('couldn\'t convert file path to file api'))
}

export const computeAudioLength = (file) => {
  return new Promise((resolve) => {
    var objectURL = URL.createObjectURL(file);
    var audioInstance = new Audio([objectURL]);
    audioInstance.addEventListener(
      "canplaythrough",
      () => {
        URL.revokeObjectURL(objectURL);
        resolve({
          file,
          duration: Math.floor(audioInstance.duration)
        });
      },
      false,
    );
  });
}


export const getFilenameFromFileOrURL = (file, url, defaultFilename) => {
  let fileName = defaultFilename;

  if (file && file.name.length) {
    fileName = file.name;
  } else if (url.length) {
    fileName = url;
  }

  const fileNameMatch = fileName.match(/([^\/\\&\?]+)\.\w{3,4}(?=[\?\&].*$|$)/);

  if (fileNameMatch) {
    fileName = fileNameMatch[1];
  }

  return fileName;
}

let CURRENT_LAYOUT_DIRECTION = '';
export const getLayoutDirection = () => {
  if (CURRENT_LAYOUT_DIRECTION) {
    return CURRENT_LAYOUT_DIRECTION;
  }

  try {
    CURRENT_LAYOUT_DIRECTION = document.body.classList.contains('ltr-layout') ? 'left' : 'right';
  } catch (e) {
    CURRENT_LAYOUT_DIRECTION = 'left';
  } finally {
    return CURRENT_LAYOUT_DIRECTION;
  }
}

export const isLayoutRTL = () => getLayoutDirection() === 'right';
export const isLayoutLTR = () => getLayoutDirection() === 'left';
export const layoutDirectionSuffix = (className, revert = false) => {
  let direction = getLayoutDirection();

  if (revert) {
    direction = direction === 'left' ? 'right' : 'left';
  }

  return `${className}-${direction}`;
}


export const loadScript = (url) => {
  return new Promise((resolve, reject) => {
    var script = document.createElement('script');
        script.src = url;
        script.type = 'text/javascript';
        script.onerror = () => reject(new Error('Script load error for ' + url));
        script.onload = () => resolve('Script loaded successfully!');

    document.head.appendChild(script);
  });
}

export const decodeFileUrl = (encodedFileUrl, shouldApplyUnescape = false) => {
  try {
    let decodedFileUrl = decodeURIComponent(encodedFileUrl);

    while (decodedFileUrl !== encodedFileUrl) {
      encodedFileUrl = decodedFileUrl;
      decodedFileUrl = decodeURIComponent(encodedFileUrl);
    }

    if (shouldApplyUnescape) {
      decodedFileUrl = unescape(decodedFileUrl);
    }

    return decodedFileUrl;
  } catch (error) {
    console.warn('Failed to decode file url', error);
    return encodedFileUrl;
  }
}

import $ from 'jquery';
import {getQueryParameterByName} from '../../../../lib/helpers';

/*=========================Vimeo=================================*/
export const VIMEO_VIDEO_PREFIX_URL = 'https://vimeo.com/';
/*=========================vooplayer=================================*/
export const VOO_PLAYER_URL = 'https://app.spotlightr.com/assets/spotlightr.js';
export const VOO_PLAYER_VIDEO_PREFIX_URL = 'https://app.spotlightr.com/watch/';
export const VOO_PLAYER_REGEX = /\.(vooplayer|spotlightr)\.com\/watch\/(.+)/i;
export const VOO_PLAYER_DOMAIN_REGEX = /\.(vooplayer|spotlightr)\.com/i;
export const VOO_PLAYER_OLD_VERSION_ROUTE = "publish";
export const VOO_PLAYER_NEW_VERSION_ROUTE = "watch";
export const VOO_PLAYER_DOMAINS = ['vooplayer', 'spotlightr'];
let callbackSpotlightrListener = null;
/*=========================Whist=================================*/
export const WHIST_PLAYER_URL = 'https://api.video-cdn.com/assets/js/com/remote.js';
export const WHIST_PLAYER_VIDEO_PREFIX_URL = 'https://www.video-cdn.com/embed/iframe/';
export const WHIST_PLAYER_DURATION_URL_FORMAT = `https://www.video-cdn.com/video/duration/{0}/AAA_CDN`;
// export const WHIST_PLAYER_URL = 'http://qa.video-cdn.com/assets/js/com/remote.js'
// export const WHIST_PLAYER_VIDEO_PREFIX_URL = 'http://qa.video-cdn.com/embed/iframe/'
export const WHIST_PLAYER_REGEX = /\.(video-cdn)\.com\/embed\/iframe\/(.+)/i;
export const WHIST_PLAYER_DOMAINS = ['video-cdn'];

//by this number voo player calculate the height:width/1.7777
export const VOO_PLAYER_NUMBER = 1.7777;

export function getVooPlayerId(videoUrl) {
  const vooPlayerExec = VOO_PLAYER_REGEX.exec(changeVooPlayerLinkToNewVersion(videoUrl));
  let playerId = null;
  if (vooPlayerExec && vooPlayerExec.length === 3
        && VOO_PLAYER_DOMAINS.includes(vooPlayerExec[1])) {
    playerId = vooPlayerExec[2];
  }
  return playerId;
}
export function isVooPlayer(videoUrl) {
  return getVooPlayerId(videoUrl) !== null;
}
export async function loadVooPlayerSDK(callback) {
  await new Promise((resolve, reject) => {
    if (!window.vooAPI) {
      $.getScript(VOO_PLAYER_URL, async () => {
        console.log('vooAPI loaded');
        resolve()
      });
    } else {
      resolve()
    }
  });
}
const setWistiaPlayerSize = (wistiaApi, width, height) => {
  wistiaApi.videoHeight(height);
  wistiaApi.videoWidth(width);
}
export const setWistiaDimensions = (wistiaApi, videoContainer, videoId, width, height) => {
  if (wistiaApi) {
    setWistiaPlayerSize(wistiaApi, width, height);
  } else {
    const videoContainerStyle = videoContainer.style;
    //hide first incorrect dimensions
    Object.assign(videoContainerStyle, { left: '-99999em', position: 'absolute' })
    window._wq = window._wq || [];
    //NOTE:with videoId onReady event is not fire always
    window._wq.push({
      id: '_all', onReady: (video) => {
        setWistiaPlayerSize(video, width, height);
        Object.assign(videoContainerStyle, { left: '', position: '' })
      }
    });
  }
}
export const setVooPlayerSize = (videoContainerStyle, clientWidth, clientHeight) => {
  const calculatedHeightByWidth = clientWidth / VOO_PLAYER_NUMBER;
  if (calculatedHeightByWidth > clientHeight || clientHeight > calculatedHeightByWidth) {
    Object.assign(videoContainerStyle, {maxWidth : `${clientHeight * VOO_PLAYER_NUMBER}px`})
  }
}

export const changeVooPlayerLinkToNewVersion = (videoUrl) => {
 return videoUrl.replace(VOO_PLAYER_OLD_VERSION_ROUTE, VOO_PLAYER_NEW_VERSION_ROUTE)
}
const isSpotlightrMessage=(origin, data)=>{
  const vooPlayerExec = VOO_PLAYER_DOMAIN_REGEX.exec(origin);

  return vooPlayerExec && data && data.data && vooPlayerExec.length === 2 &&
    VOO_PLAYER_DOMAINS.includes(vooPlayerExec[1])
}
export const getVooPlayerDataByAction = async (methodName) => {
  return await new Promise((resolve) => {
  const callback = (e) => {
      const { origin, data } = e
      if (isSpotlightrMessage(origin, data) && data.data.method == methodName) {
        //remove event listener
        window.removeEventListener('message', callback)
        resolve(data.data)
      }
    }
    window.addEventListener('message', callback)
  })
}
export const listenSpotlightrReady = async () => {
  if(!callbackSpotlightrListener) {
    callbackSpotlightrListener = (e) => {
      const { origin, data } = e
      if (isSpotlightrMessage(origin, data) && data.data.name == 'playerSettings') {
        console.log('playerSettings ready');
        //remove event listener
        window.removeEventListener('message', callbackSpotlightrListener)
        callbackSpotlightrListener = null
        document.dispatchEvent(new CustomEvent('onPlayerReady', {bubbles: true, detail: true}))
      }
    }
    console.log('start listening to spotlightr playerSettings event');
    window.addEventListener('message', callbackSpotlightrListener);
  }
}
/*===========================================Whist Player====================================*/
export function getWhistPlayerId(videoUrl) {
  const whistPlayerExec = WHIST_PLAYER_REGEX.exec(videoUrl);
  let playerId = null;
  if (whistPlayerExec && whistPlayerExec.length === 3 && WHIST_PLAYER_DOMAINS.includes(whistPlayerExec[1])) {
    playerId = whistPlayerExec[2];
  }
  return playerId;
}

export function isWhistPlayer(videoUrl) {
  return getWhistPlayerId(videoUrl) !== null;
}
/*===========================================common====================================*/
export const getVimeoUrl = (url, videoId) => {
  let vimeoUrl = `${VIMEO_VIDEO_PREFIX_URL}${videoId}`;
  const subtitleLanguage = getQueryParameterByName('texttrack', url);

  if (subtitleLanguage) {
    vimeoUrl = `${vimeoUrl}?texttrack=${subtitleLanguage}`;
  }
  return vimeoUrl;
}

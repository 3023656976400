import React, { useEffect } from 'react';
import { isUndefined } from 'lodash';
import { useSelector } from 'react-redux';

import { scrollToView } from '../../../components/helpers/school';
import ArrowDown from '../../../components/svg/arrow-down';
import ConditionalComponent from '../../../components/common/ConditionalComponent';

import TinyMceEditorContent from '../common/TinyMceEditorContent';
import SchoolButtonsBar from './SchoolButtonsBar';
import SchoolFooter from '../common/SchoolFooter';
import SchoolNotification from '../common/SchoolNotification';
import SkipTo from '../../../components/skip_to';
import ArrowToCourseYoga from '../../../components/svg/arrow_to_course_yoga';
import UseEffectInitializer from '../common/use_effect_initializer';



export default function School({ children }) {
  const {
    actionButtonLabel,
    altColor,
    cover,
    error,
    flashText,
    headerText,
    homepagePath,
    i18n,
    logo,
    signedIn,
    signedInUserName,
    signinPath,
    signoutPath,
    signupPath,
    subheaderText,
    templateName,
    textColor,
    lastLoadedUrl,
    schoolData,
  } = useSelector(state =>
  ({
    altColor: state.$$school.getIn(['template_values', 'secondaryColor', 'value']),
    actionButtonLabel: state.$$school.getIn(['template_values', 'actionButtonLabel', 'value']),
    cover: state.$$school.get('cover'),
    error: state.$$layout.get('jserror'),
    flashText: state.$$layout.getIn(['flash', 'text']),
    headerText: state.$$school.getIn(['template_values', 'displayHeader', 'value']),
    homepagePath: state.$$layout.getIn(['urls', 'root']),
    i18n: state.$$i18n,
    logo: state.$$school.get('logo'),
    signedIn: state.$$account.get('signedIn'),
    signedInUserName: state.$$account.get('name_or_email'),
    signinPath: state.$$layout.getIn(['urls', 'login']),
    signoutPath: state.$$layout.getIn(['urls', 'logout']),
    signupPath: state.$$layout.getIn(['urls', 'signup']),
    subheaderText: state.$$school.getIn(['template_values', 'displaySubheader', 'value']),
    templateName: state.$$school.get('template_name'),
    textColor: state.$$school.getIn(['template_values', 'textColor', 'value']),
    lastLoadedUrl: state.$$layout.getIn(['lastLoadedUrl']),
    schoolData: state.$$school,
  }))

  const onClickToView = (event) => {
    event.preventDefault();
    scrollToView();
  }

  const isBundlePage = lastLoadedUrl.includes('/bundles');
  const headerSizeStyle = !isBundlePage ? 'school-topbar' : '';
  const bundlePageBg = isBundlePage ? 'bundle-page-bg' : 'school-page';

  const headerWrapperStyle = { backgroundImage: cover ? `url('${cover}')` : 'none' };
  const buttonLabel = actionButtonLabel || i18n.getIn(['students', 'school', 'startLearning']);
  const skipToMainText = i18n.getIn(['students', 'general', 'skipToMainContent']);

  return (
    <div className={`school-template-${templateName} school-container ${bundlePageBg}`}>
      <SkipTo links={{ main: skipToMainText }} />
      <UseEffectInitializer />

      <div className='school-buttonsbar-white-wrap display-web'>
        <SchoolButtonsBar
          altColor={altColor}
          homepagePath={homepagePath}
          i18n={i18n}
          logo={logo}
          signedIn={signedIn}
          signedInUserName={signedInUserName}
          signinPath={signinPath}
          signoutPath={signoutPath}
          signupPath={signupPath}
          textColor={textColor}
        />
      </div>

      <div className={`${headerSizeStyle} school-header-wrapper`} style={headerWrapperStyle}>
        <div className='school-buttonsbar-white-wrap display-mobile'>
          <SchoolButtonsBar
            altColor={altColor}
            homepagePath={homepagePath}
            i18n={i18n}
            logo={logo}
            signedIn={signedIn}
            signedInUserName={signedInUserName}
            signinPath={signinPath}
            signoutPath={signoutPath}
            signupPath={signupPath}
            textColor={textColor}
          />
        </div>
        <div className="school-grid school-header-text-container">

          <TinyMceEditorContent className='school-header' text={headerText} />
          {isBundlePage && (
            <TinyMceEditorContent className='school-subheader' text={subheaderText} />
          )}

        </div>

        {isBundlePage && (
          <div className='arrow-to-course'>
            <a href="#gray-bundles-tab">
              <ArrowToCourseYoga bgColor={altColor} />
            </a>
          </div>
        )}
      </div>

      <ConditionalComponent isRender={!isUndefined(error)}>
        <SchoolNotification text={error} className="error" />
      </ConditionalComponent>

      <ConditionalComponent isRender={!isUndefined(flashText)}>
        <SchoolNotification text={flashText} className="warning" />
      </ConditionalComponent>

      <main id='main'>
        {children}
      </main>

      <SchoolFooter i18n={i18n} schoolData={schoolData} />
    </div>
  )
}

import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class QuizDetailedResults extends React.Component {
  static propTypes = {
    quiz: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const quizDetailedResults= document.getElementsByClassName('lesson-with-quiz-out-transition')[0]
    quizDetailedResults.scrollTo(0,0)
  }

  wrongAnswerExplanation = (explanation, answerIndex) => {
    if (typeof explanation === 'string' && explanation !== "") {
      return (
        <p className="wrong-answer-description">
          <span dangerouslySetInnerHTML={{ __html: this.props.i18n.wrongAnswer.replace('%{answer_number}', answerIndex) }}></span>
          &nbsp;-&nbsp;
          <span dangerouslySetInnerHTML={{ __html: explanation }}></span>
        </p>
      );
    }
  }

  correctAnswerExplanation = (explanation, answerIndex) => {
    if (typeof explanation === 'string' && explanation !== "") {
      return (
          <p className="correct-answer-description">
            <span dangerouslySetInnerHTML={{ __html: this.props.i18n.correctAnswer.replace('%{answer_number}', answerIndex) }}></span>
            &nbsp;-&nbsp;
            <span dangerouslySetInnerHTML={{ __html: explanation }}></span>
          </p>
      );
    }
  }

  renderMedia(mediaURL, altText, mediaType) {
    const sanitizedUrl = mediaURL.replace(/[^a-zA-Z0-9]/g, '_');
    let fileType = "Video";

    if (sanitizedUrl.indexOf('response_content_type_image') !== -1) {
      fileType = "Image";
    } else if (sanitizedUrl.indexOf('response_content_type_audio') !== -1) {
      fileType = "Audio";
    }

    if (fileType === "Image") {
      return (
        <img className={`quiz-${mediaType}-image-student`} src={mediaURL} alt={altText} />
      );
    } else if (fileType === "Audio") {
      return (
        <audio controls controlsList="nodownload" className={`quiz-${mediaType}-audio-student`}>
          <source src={mediaURL}/>
        </audio>
      );
    } else if (fileType === "Video"){
      return (
        <div className={`quiz-${mediaType}-video-student`} >
          <ReactPlayer controls={true} url={mediaURL} playing={false} className="custom-react-player"/>
        </div>
      );
    }
  }

  render() {
    const { quiz } = this.props;
    const quizPartialFeedback = quiz.get('partial_feedback');
    let submissions = {};

    try {
      submissions = JSON.parse(quiz.get('submitted'));
    } catch (e) {
      // failed to parse. never mind we'll live
    }

    return (
      <div>

        {quiz.get('questions').map((question, questionIndex) => (
          <form key={`question-${ questionIndex }`} className="question-block">
            <div className="question-block--with-required">
              <h3
                className="question-block--title"
                dangerouslySetInnerHTML={{
                  __html: this.props.i18n.questionNumber.replace('%{question_number}', questionIndex + 1)
                }}>
              </h3>
            </div>
            <p
              className='question-block--text'
              dangerouslySetInnerHTML={{ __html: question.get('q') }}
              aria-level="4">
            </p>

            {question.get('questionMedia') !== "" && typeof question.get('questionMedia') === 'string' && question.get('questionMedia') !== null ? (
                this.renderMedia(
                    question.get('questionMedia'),
                    question.get('questionMediaDescription'),
                    'question'
                )
            ) : null}

            <div className={`answers ${quizPartialFeedback ? 'only-right-answers-feedback' : 'all-answers-feedback'} ${question.get('correctQuestion') ? 'question-marked-correctly-in-quiz' : ''}`}>
              {question.get('a').map((answer, answerIndex) => (
                <div
                  key={`question-${questionIndex}-answer-${answerIndex}`}
                  className={`question-wrap ${answer.get('correct') ? 'answer-correct' : 'answer-error'} ${answer.get('marked') ? 'answer-marked-in-quiz' : 'answer-unmarked-in-quiz'}`}
                >
                  <div className={`nm-check-block ${answer.get('marked') ? 'answer-in-quiz-student' : answer.get('correct') ? 'correct-unmarked-answer' : ''} ${(answer.get('marked') && answer.get('correct')) ? 'correct-answer-in-quiz-student' : 'wrong-answer-in-quiz-student'} ${question.get('correctQuestion') ? 'correct-question-in-quiz' : ''}`}>
                    <span className="question-marker">{answerIndex + 1}.</span>

                    <input
                      type="checkbox"
                      className="nm-hidden"
                      disabled={true}
                      checked={answer.get('marked')}
                      name="course_radio"
                      id={`check-${questionIndex + 1}-${answerIndex}`}
                    />

                    <label htmlFor={`check-${questionIndex + 1}-${answerIndex}`} className="nm-checkbox answer-input-radio-btn"></label>
                    <label
                      className="nm-label-text"
                      dangerouslySetInnerHTML={{ __html: answer.get('text') }}
                      htmlFor={`qa-check-${questionIndex}-${answerIndex}`}>
                    </label>
                  </div>


                  {answer.get('media') !== "" && typeof answer.get('media') === 'string' && answer.get('media') !== null ? (
                      this.renderMedia(
                          answer.get('media'),
                          answer.get('answerAltText'),
                          'answer'
                      )
                  ) : null}

                  {!quizPartialFeedback
                    ? answer.get('correct') ? (
                        this.correctAnswerExplanation(question.get('correctAnswerExplanation'), answerIndex + 1)
                      ) : (
                        this.wrongAnswerExplanation(answer.get('explanation'), answerIndex + 1)
                        ) : null
                  }

                  {quizPartialFeedback
                    ? question.get('correctQuestion')
                      ? answer.get('correct')
                        ? this.correctAnswerExplanation(question.get('correctAnswerExplanation'), answerIndex + 1)
                        : this.wrongAnswerExplanation(answer.get('explanation'), answerIndex + 1)
                      : answer.get('marked')
                        ? this.wrongAnswerExplanation(answer.get('explanation'), answerIndex + 1)
                        : null
                    : null
                  }
                </div>
              ))}
            </div>
          </form>
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    i18n: state.$$i18n.getIn(['students', 'lesson', 'quiz']).toJS()
  };
}

export default connect(mapStateToProps)(QuizDetailedResults);

import React from 'react';
import { connect } from 'react-redux';

import { Form, HiddenField, Input, PasswordField} from './form_for';
import PasswordFormLogo from '../svg/password_form_logo';

class SchoolPasswordReset extends React.Component {

  render() {
    const { resetPasswordToken, i18n, textColor, altColor } = this.props;

    return (
      <div className="content-inner">
        <div className="iconed-block">
          <div className="iconed-block-inner">
            <div className="col-set col-set-bordered password-form-direction school-edit-password-form">
              <div className="col">
                <div className="inline-block">
                  <PasswordFormLogo fillColor={altColor} />
                </div>

                <h3 className='new-user-form-header' arial-label={i18n.resetPassword.updateTitleAriaLabel} dangerouslySetInnerHTML={{ __html: i18n.resetPassword.updateTitle }}></h3>

                <Form for="user" action="user_password_path" method="PUT" className='nm-course-form clear padded-form'>
                  <HiddenField name="reset_password_token" value={resetPasswordToken} />

                  <div dangerouslySetInnerHTML={{ __html: this.props.deviseerrors }} />

                  <div className='nm-form-row'>
                    <div className='nm-left'>
                      <PasswordField id="password" name="password" className='nm-input input-password' placeholder={i18n.resetPassword.updatePassword} />
                    </div>
                  </div>

                  <div className='nm-form-row'>
                    <div className='nm-left'>
                      <PasswordField id="password_confirmation" name="password_confirmation" className='nm-input input-password' placeholder={i18n.resetPassword.updatePasswordConfirmation} />
                    </div>
                  </div>

                  <input
                    type="submit"
                    arial-label={i18n.resetPassword.updateButtonAriaLabel}
                    style={{color: textColor, backgroundColor: altColor}}
                    className='nm-btn reset-password-button school-reset-password handle-alignment'
                    value={i18n.resetPassword.updateButton}
                  />
                </Form>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    textColor: state.$$school.getIn(['template_values', 'textColor', 'value']),
    altColor: state.$$school.getIn(['template_values', 'secondaryColor', 'value']),
  };
}

export default connect(mapStateToProps)(SchoolPasswordReset);

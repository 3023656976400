import React from 'react';
import { connect } from 'react-redux';
import { clearError } from '../../../actions/layout';

import { getAndHideFlushContainerErrors } from '../../../components/forms/form_for';
import Container from '../../container';
import Links from '../../../constants/links';
import LocalStorageManager from '../../../../../lib/localStorageManager';
import SchoolerLogo from '../../../components/logo';
import SkipTo from '../../../components/skip_to';
import TinyMceEditorContent from '../common/TinyMceEditorContent';
import UseEffectInitializer from '../common/use_effect_initializer';

const Logo = connect(state => ({
  logo: state.$$school.get('logo'),
  homepagePath: state.$$layout.getIn(['urls', 'root']),
}))((props) => {
  return (
    <a href={props.homepagePath} className={props.className}>
      <img
        src={props.logo}
        alt='school logo'
        width={150}
      />
    </a>
  );
});

//school scroll element
const STORAGE_KEY = 'sse';
const ELEMENT_CLASS_NAME = '.content-inner';
class School extends Container {
  static defaultProps = {
    internalScroll: true,
    allowMobile: true,
    mobileNotes: false,
    hideBottomPanel: false,
    className: '',
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.state = {
      showUserMenu: false,
      fullScreenMode: false,
      mobileNavOpen: false,
    };
  }
  componentDidMount() {
    // we need scroll to element after refresh page
    this.scrollToElement();
    getAndHideFlushContainerErrors();
  }

  clearErrors = () => {
    this.props.dispatch(clearError());
  };

  onButtonBarAction = () => {
    // check if page have scroll
    if (window.innerWidth > document.documentElement.clientWidth) {
      LocalStorageManager.save(STORAGE_KEY, ELEMENT_CLASS_NAME);
      // in case when user click on action and scroll
      // to top and click again on same action
      this.scrollToElement();
    }
  };
  scrollToElement() {
    const elementClass = LocalStorageManager.get(STORAGE_KEY);
    if (elementClass) {
      const element = document.querySelector(elementClass);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView();
          LocalStorageManager.remove(STORAGE_KEY);
        }, 0);
      }
    }
  }
  render() {
    const {
      headerText,
      subheaderText,
      textColor,
      cover,
      altColor,
      i18n,
      signedIn,
      signinPath,
      signoutPath,
      signupPath,
      signedInUserName,
      error,
      flashText,
      template_name
    } = this.props;

    const leftButtonText = (signedIn ? i18n.general.logout : i18n.general.login);
    const leftButtonAriaLabel = (signedIn ? i18n.general.logoutAriaLabel : i18n.general.loginAriaLabel);
    const leftButtonTestName = (signedIn ? 'signout-button' : 'signin-button');
    const leftButtonHref = (signedIn ? signoutPath : signinPath);
    const headerWrapperStyle = cover ? { backgroundImage: `url(${cover}` } : {};

    return (
      <div className='school-container'>
        <SkipTo links={{ main: i18n.general.skipToMainContent }} />
        <UseEffectInitializer />

        <div
          className='header-wrapper'
          style={headerWrapperStyle}
        >
          <div className='buttons-bar'>
            <Logo className='logo' />

            {!signedIn &&
              <a
                href={signupPath}
                aria-label={i18n.general.registrationAriaLabel}
                className='school-button right'
                style={{ color: textColor, background: altColor }}
                onClick={this.onButtonBarAction}
                dangerouslySetInnerHTML={{
                  __html: i18n.general.registration
                }}>
              </a>
            }

            <a
              aria-label={leftButtonAriaLabel}
              className='school-button left only-desktop'
              dangerouslySetInnerHTML={{ __html: leftButtonText }}
              data-testname={leftButtonTestName}
              href={leftButtonHref}
              style={{ color: textColor }}
            >
            </a>

            <a
              aria-label={leftButtonAriaLabel}
              className='school-button left only-mobile'
              dangerouslySetInnerHTML={{ __html: leftButtonText }}
              data-testname={leftButtonTestName}
              href={leftButtonHref}
              onClick={this.onButtonBarAction}
              style={{ color: altColor, background: textColor }}
            >
            </a>

            {signedIn &&
              <div className="welcome" style={{ color: textColor }} dangerouslySetInnerHTML={{
                __html: i18n.school.welcome.replace('%{student_name}', signedInUserName)
              }}></div>
            }
          </div>

          <TinyMceEditorContent className='row header school-header-text' text={headerText} />
          <TinyMceEditorContent className='row subheader school-subheader-text' text={subheaderText} />
        </div>

        {error &&
          <div className="school-notification danger">
            <span className="text">{error}</span>
          </div>
        }

        {flashText &&
          <div className="school-notification warning">
            <span className="text">{flashText}</span>
          </div>
        }

        <main id='main'>
          {this.props.children}
        </main>

        {this.props.hasA11yStatement ? (
          <footer className="static-footer credits a11y-school-footer">
            <SchoolerLogo />
            <div className="links-container">
              <a href={Links.SCHOOLER_HOMEPAGE_URL} target="_blank" className="top-link">
                {i18n.credit}
              </a>
              <a href={this.props.previewSchoolStatementPath} className="bottom-link" target="_blank">
                {i18n.a11yStatement}
              </a>
            </div>
          </footer>
        ) : (
          <footer className="footer-wrapper">
            <a href={Links.SCHOOLER_HOMEPAGE_URL} aria-label={i18n.creditAriaLabel} target="_blank">
              <SchoolerLogo />
              <div className='logo-description'>
                {i18n.credit}
              </div>
            </a>
          </footer>
        )}
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    altColor: state.$$school.getIn(['template_values', 'secondaryColor', 'value']),
    cover: state.$$school.get('cover'),
    error: state.$$layout.get('jserror'),
    explicit: state.$$school.get('explicit'),
    flashText: state.$$layout.getIn(['flash', 'text']),
    hasA11yStatement: state.$$school.get('has_a11y_statement'),
    headerText: state.$$school.getIn(['template_values', 'displayHeader', 'value']),
    previewSchoolStatementPath: state.$$school.get('preview_school_statement'),
    template_name: state.$$school.get('template_name'),
    i18n: {
      credit: state.$$i18n.getIn(['system', 'credit']),
      a11yStatement: state.$$i18n.getIn(['system', 'a11yStatement']),
      general: state.$$i18n.getIn(['students', 'general']).toJS(),
      school: state.$$i18n.getIn(['students', 'school']).toJS(),
      creditAriaLabel: state.$$i18n.getIn(['system', 'creditAriaLabel']),
    },
    signedIn: state.$$account.get('signedIn'),
    signedInUserName: state.$$account.get('name_or_email'),
    signinPath: state.$$layout.getIn(['urls', 'login']),
    signoutPath: state.$$layout.getIn(['urls', 'logout']),
    signupPath: state.$$layout.getIn(['urls', 'signup']),
    subheaderText: state.$$school.getIn(['template_values', 'displaySubheader', 'value']),
    textColor: state.$$school.getIn(['template_values', 'textColor', 'value']),
  };
}

export default connect(mapStateToProps)(School);

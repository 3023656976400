import React from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import actions from '../actions/quiz';
import $ from 'jquery';

class Quiz extends React.Component {
  static propTypes = {
    quiz: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  onSubmit = (e) => {
    e.preventDefault();
    const formData = $(e.target).serialize();
    const canSubmit = this.renderErrorForRequiredQuestions(formData)
    if(canSubmit){
      this.props.dispatch(actions.submitResults(formData, this.props.quiz.get('submitUrl')));
    }
  };

  renderErrorForRequiredQuestions(formData){
    const urlSearchParams = new URLSearchParams(formData);
    const formDataJSONString = urlSearchParams.get('quiz-questions-and-answers');
    const parsedFormData = JSON.parse(formDataJSONString);
    let submit = true;

    parsedFormData.forEach((question, questionIndex) => {
      document.querySelectorAll('.question-block--title')[questionIndex].style.color = '#2E4B4F';
      document.querySelector('.required-error-message').style.display = 'none';
      document.querySelectorAll('.question-error')[questionIndex].style.display = 'none';
    });

    parsedFormData.forEach((question, questionIndex) => {
      if(question.isRequired === true){
        const questionArray = [];
        const answers = document.querySelectorAll(`.radio-for-question-answer-${questionIndex}`);

        answers.forEach(answer => {
          questionArray.push(answer.checked);
        });

        if(!questionArray.includes(true)){
          document.querySelectorAll('.question-block--title')[questionIndex].style.color = '#D90808'
          document.querySelector('.required-error-message').style.display = 'block'
          document.querySelectorAll('.question-error')[questionIndex].style.display = 'block'
          this.scrollTop();
          submit = false
        }
      }
    });

    return submit
  };

  scrollTop(){
    const quizDetailedResults= document.getElementsByClassName('lesson-with-quiz-out-transition')[0];
    quizDetailedResults.scrollTo(0,0);
  }

  renderQuizInstructions(retriesCount, retriesQuotaExceeded, blocking) {
    const { i18n } = this.props;
    const limitedRetries = (typeof retriesCount === 'number');
    const retriesQuotaText = retriesQuotaExceeded
      ? i18n.triesQuotaExceeded
      : i18n.triesCount.replace('%{number_of_tries}', retriesCount);

    const blockingText = i18n.blockingText

    return (
      <fieldset className='question-block quiz-attempts-and-errors'>
        {limitedRetries &&
          <p
            className='question-block--text attempts-text'
            dangerouslySetInnerHTML={{ __html: retriesQuotaText }}
            aria-level="4">
          </p>
        }

        {blocking &&
          <p
            className='blocking-quiz-text'
            dangerouslySetInnerHTML={{ __html: blockingText }}
            aria-level="4">
          </p>
        }
        <h1 className="required-error-message">{i18n.defaultRequiredError}</h1>
      </fieldset>
    );
  }

  renderMedia(mediaURL, altText, mediaType) {
    const sanitizedUrl = mediaURL.replace(/[^a-zA-Z0-9]/g, '_');
    let fileType = "Video";
    const vimeoPreview = sanitizedUrl.includes('vimeo') ? 'vimeo-preview' : 'player-preview'

    if (sanitizedUrl.indexOf('response_content_type_image') !== -1) {
      fileType = "Image";
    } else if (sanitizedUrl.indexOf('response_content_type_audio') !== -1) {
      fileType = "Audio";
    }

    if (fileType === "Image") {
      return (
        <img className={`quiz-${mediaType}-image-student quiz-media`} src={mediaURL} alt={altText} />
      );
    } else if (fileType === "Audio") {
      return (
        <audio controls controlsList="nodownload" className={`quiz-${mediaType}-audio-student quiz-media`}>
          <source src={mediaURL}/>
        </audio>
      );
    } else if (fileType === "Video"){
      return (
        <div className={`quiz-${mediaType}-video-student quiz-media`} >
          <ReactPlayer controls={true} url={mediaURL} playing={false} className={`custom-react-player ${vimeoPreview}`} />
        </div>
      );
    }
  }

  render() {
    const { quiz, i18n, sidebarBgColor, textColor } = this.props;
    const { questions, submitUrl, header, retries } = quiz.toObject();
    const numberOfRetries = retries !== null ? retries : i18n.unlimitedRetries;
    const hasRetriesQuota = (typeof numberOfRetries === 'number');
    const retriesQuotaExceeded = hasRetriesQuota && (numberOfRetries <= 0);
    const canSubmit = !(hasRetriesQuota && retriesQuotaExceeded);
    const questionNumberBoolean = i18n.questionNumber.includes('Question');
    const blocking = quiz.get('blocking')
    const userColors = {
      '--label-bg-color': sidebarBgColor,
      color: textColor,
    };

    return (
      <div>
        <div className="page-lable quiz-header" style={userColors}>
            <h2 tabIndex="0" aria-label={i18n.quizSupportingTextAriaLabel}>{header}</h2>
        </div>

        <form
          className='quiz'
          method='POST'
          action={submitUrl}
          onSubmit={this.onSubmit}
          noValidate
        >
          <input
            type='hidden'
            name='quiz-questions-and-answers'
            value={JSON.stringify(questions.toJS())}
          />

          {this.renderQuizInstructions(numberOfRetries, retriesQuotaExceeded, blocking)}

          {questions.map((question, questionIndex) => (
            <fieldset key={`question-${ questionIndex }`} className='question-block'>
              <div className="question-block--with-required">
                <h2
                  className="question-block--title"
                  dangerouslySetInnerHTML={{
                    __html: `<span>${i18n.questionNumber.replace('%{question_number}', (questionNumberBoolean ? '# ': '#')  + (questionIndex + 1))}</span>`
                  }}>
                </h2>

                {question.get('isRequired') && (
                      <span className="required-asterisk"> *</span>
                )}
              </div>
              <p className="question-error">{i18n.requiredErrorForQuestion}</p>
              <p
                className='question-block--text'
                dangerouslySetInnerHTML={{ __html: question.get('text') }}
                aria-level="4">
              </p>

              {question.get('questionMedia') !== '' && typeof question.get('questionMedia') === 'string' && question.get('questionMedia') !== null ? (
                  this.renderMedia(
                      question.get('questionMedia'),
                      question.get('questionMediaDescription'),
                      'question'
                  )
              ) : null}

              <div className='answers'>
                {question.get('answers').map((answer, answerIndex) => (
                  <div key={`question-${questionIndex}-answer-${answerIndex}`} className="question-wrap single-answer-div-in-quiz">
                    <div className="nm-check-block">
                      <span className="question-marker"></span>

                      <input
                        type="radio"
                        className={`nm-hidden radio-for-question-answer-${questionIndex}`}
                        name={`answers[${questionIndex}]`}
                        id={`qa-check-${questionIndex}-${answerIndex}`}
                        value={answer.get('text')}
                        defaultChecked={answer.get('marked')}
                        required={question.get('isRequired')}
                      />

                      <label className="nm-checkbox answer-input-radio-btn" htmlFor={`qa-check-${questionIndex}-${answerIndex}`}></label>
                      <label
                        className="nm-label-text answer-text-student-side"
                        htmlFor={`qa-check-${questionIndex}-${answerIndex}`}>
                        {answer.get('text')}
                      </label>
                    </div>

                    {answer.get('media') !== '' && typeof answer.get('media') === 'string' && answer.get('media') !== null ? (
                        this.renderMedia(
                            answer.get('media'),
                            answer.get('altText'),
                            'answer'
                        )
                    ) : null}

                  </div>
                ))}
              </div>
            </fieldset>
          ))}

          {
            canSubmit && (
              <fieldset className='question-block'>
                <input type="submit" className="nm-btn quiz-submit-btn" aria-label={i18n.finishATestAriaLabel} value={i18n.finishATest} />
              </fieldset>
            )
          }
        </form>
        <div style={{clear: 'both'}} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    i18n: state.$$i18n.getIn(['students', 'lesson', 'quiz']).toJS(),
    sidebarBgColor: state.$$theme.getIn(['settings', 'background_color']),
    textColor: state.$$theme.getIn(['settings', 'text_color']),
  };
}

export default connect(mapStateToProps)(Quiz);
